const donationForm = ($) => {
	jQuery(document).ready(function ($) {
		// Append a hidden input field to the WooCommerce donation form

		$('.widget_wc-donation-widget').each(function () {
			$(this).append(
				'<input type="hidden" id="selected-honoured-members" name="selected_honoured_members" value="">'
			);
		});
	});
};

export default donationForm;
