import $ from 'jquery';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

import 'slick-carousel';

function flexibleMediaGallery() {
	$(document).ready(() => {
		const swiper = new Swiper('.swiper-container', {
			modules: [Navigation],
			effect: 'coverflow',
			grabCursor: true,
			centeredSlides: true,
			slidesPerView: 'auto',
			loop: true,
			observer: true,
			observeParents: true,

			coverflowEffect: {
				rotate: 0,
				stretch: 300,
				depth: 200,
				modifier: 1,
				slideShadows: true,
			},

			navigation: {
				nextEl: '.slider-arrow_right',
				prevEl: '.slider-arrow_left',
			},
		});
		$('.slick-wrapper').slick({
			centerMode: true,
			centerPadding: '15%',
			arrows: false,
			dots: true,

			responsive: [
				{
					breakpoint: 800,
					settings: {
						centerPadding: '20%',
					},
				},
			],
		});
	});
}

export default flexibleMediaGallery;
